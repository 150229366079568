{
	"type": "FeatureCollection",
	"features": [{
		"type": "Feature",
		"properties": {
			"id": 1,
			"name": "Quartier Saint-Roch (Québec)"
		},
		"geometry": {
			"type": "Point",
			"coordinates": [ -71.22685, 46.81558 ]
		}
	}]
}
